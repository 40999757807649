<template>
  <div class="app">
    <header>
      <div class="logo">
        <img :src="logo" alt="Logo" />
      </div>
      <nav>
        <a href="#">Home</a> |
        <a href="#" @click.prevent="showAboutModal = true">About</a> |
        <a href="#" @click.prevent="showServicesModal = true">Services</a> |
        <a href="#" @click.prevent="showVideoModal = true">How it works</a> |
        <a href="#" @click.prevent="showContactModal = true">Contact Us</a>
      </nav>
    </header>
    <main>
      <div class="content-section">
        <div class="section-1">
          <h2>Welcome to Our Service</h2>
          <p>We offer a range of AI solutions designed to integrate seamlessly with your existing EMR software. Our solutions help physicians by summarizing patient information, allowing them to query this information using an LLM, and much more.</p>
          <ul>
            <li>AI-driven patient summaries</li>
            <li>Seamless EMR integration</li>
            <li>High-security standards</li>
            <li>HIPAA compliant</li>
          </ul>
        </div>
        <div class="divider"></div>
        <div class="section-2">
          <h2>About Our Technology</h2>
          <p>Our cutting-edge technology leverages the power of AI to provide real-time insights and data analysis for healthcare professionals. By integrating with your current EMR systems, we ensure that you can focus on what matters most - patient care.</p>
          <ol>
            <li>Real-time data analysis</li>
            <li>Enhanced decision-making</li>
            <li>Streamlined workflows</li>
            <li>Scalable solutions</li>
          </ol>
        </div>
      </div>
    </main>

    <!-- About Modal -->
    <div v-if="showAboutModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="showAboutModal = false">&times;</span>
        <h2>About Mark Levy</h2>
        <img :src="markImage" alt="Mark Levy">
        <p>Mark Levy is a highly experienced professional in the healthcare technology field. With a robust background in integrating AI solutions with EMR systems, Mark has been at the forefront of innovative healthcare solutions.</p>
      </div>
    </div>

    <!-- Services Modal -->
    <div v-if="showServicesModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="showServicesModal = false">&times;</span>
        <h2>Our Services</h2>
        <p>Our seamless process of pulling health record information allows summarized patient info and highlights important information for healthcare providers. By integrating with existing EMR systems, we enable healthcare professionals to:</p>
        <ul>
          <li>Effortlessly access and retrieve patient records</li>
          <li>Receive AI-generated summaries of patient histories</li>
          <li>Identify and highlight critical information quickly</li>
          <li>Streamline decision-making processes</li>
          <li>Improve overall patient care and outcomes</li>
        </ul>
      </div>
    </div>

    <!-- Video Modal -->
    <div v-if="showVideoModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="showVideoModal = false">&times;</span>
        <h2>How It Works</h2>
        <video controls>
          <source :src="videoSrc" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
    </div>

    <!-- Contact Modal -->
    <div v-if="showContactModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="showContactModal = false">&times;</span>
        <h2>Contact Us</h2>
        <p>If you have any questions or would like to get in touch, please click the link below to open your email client:</p>
        <p><a href="mailto:admin@emrinsightai.com">Email Us: admin@emrinsightai.com</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import logo from './assets/logo.png'; // Import the logo
import markImage from './assets/mark.png'; // Import the mark image
import videoSrc from './assets/awesomeVideo.mp4'; // Import the video

export default {
  name: 'App',
  data() {
    return {
      logo, // Assign the imported logo to a data property
      markImage, // Assign the imported mark image to a data property
      videoSrc, // Assign the imported video to a data property
      showAboutModal: false, // Control the visibility of the About modal
      showServicesModal: false, // Control the visibility of the Services modal
      showVideoModal: false, // Control the visibility of the Video modal
      showContactModal: false, // Control the visibility of the Contact modal
    };
  }
};
</script>

<style scoped>
.app {
  font-family: Arial, sans-serif;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: #f0f0f0; /* Slight grey background */
  border-bottom: 1px solid #000;
}

.logo {
  margin-bottom: 1rem;
}

nav a {
  text-decoration: none;
  color: #000;
  margin: 0 0.5rem;
  cursor: pointer;
}

main {
  background-image: url('https://www.toptal.com/designers/subtlepatterns/uploads/dot-grid.png'); /* Textured background */
  background-repeat: repeat;
  padding: 2rem;
}

.content-section {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.section-1, .section-2 {
  flex: 1;
  padding: 1rem;
  background-color: #fff; /* White background for content sections */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.divider {
  width: 1px;
  background-color: #000;
  margin: 0 1rem;
}

/* Modal Styles */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-content img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.modal-content video {
  width: 100%;
}

.modal-content a {
  color: #007BFF;
  text-decoration: none;
}

.modal-content a:hover {
  text-decoration: underline;
}
</style>
